import { useRef } from 'react';
import WorkList from '../WorkList';
import Typography from '../Typography';
import LinkWithTransition from '../LinkWithTransition';

export default function MainSectionWork() {
    const sectionWorkRef = useRef();
    
    return (
        <section className="MainSectionWork" ref={sectionWorkRef}>
            <div className="section-title">
                <Typography txt="work" animate={true} />
                <Typography txt="archive" animate={true} />
            </div>
            <ul className="work-list">
                <WorkList to="work/lexus" title="렉서스 라운지" sub="lexus lounge" img={process.env.PUBLIC_URL + '/image/img_work_thumb_lexus_award.webp'}/>
                <WorkList to="work/shinsegae" title="신세계 쇼핑 앱" sub="shinsegae shopping" img={process.env.PUBLIC_URL + '/image/img_work_thumb_shinsegae_award.webp'} />
                <WorkList to="work/archiveat" title="아카이빗" sub="archiveat" img={process.env.PUBLIC_URL + '/image/img_work_thumb_archiveat_award.webp'}/>
                <WorkList to="work/ekolon" title="이코오롱 멤버십" sub="E-KOLON membership" img={process.env.PUBLIC_URL + '/image/img_work_thumb_ekolon.webp'}/>
                <WorkList to="work/bentley" title="벤틀리 멤버십" sub="bentley membership" img={process.env.PUBLIC_URL + '/image/img_work_thumb_bentley_award.webp'}/>
                <WorkList to="work/ddb" title="달다방" sub="daldabang" img={process.env.PUBLIC_URL + '/image/img_work_thumb_ddb_award.webp'}/>
            </ul>

            <LinkWithTransition to="/work" className="btn-view-all">
                view all project
            </LinkWithTransition>
        </section>
    );
}
