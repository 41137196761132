import { motion, useInView } from 'framer-motion';
import { useRef, useEffect, useState } from 'react';
import { workDetailPath } from '../../pages/WorkDetail';
import Fadeup from '../Fadeup';
import Typography from '../../../pc/components/Typography';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectFade, Thumbs } from 'swiper';
import 'swiper/css';
import "swiper/css/effect-fade";

// 스와이프 이미지
const images = [
    {main : 'img_sec7_1_mo.webp', topThumb: 'text_area_desc01_01.webp', bottomThumb: 'text_area_desc02_01.webp'}
    ,
    {main : 'img_sec7_2_mo.webp', topThumb: 'text_area_desc01_02.webp', bottomThumb: 'text_area_desc02_02.webp'}
    ,
    {main : 'img_sec7_3_mo.webp', topThumb: 'text_area_desc01_03.webp', bottomThumb: 'text_area_desc02_03.webp'}
]

// 스와이프 섹션 배경 
const bgImages = [
    "img_bg_sec7_1_mo.webp",
    "img_bg_sec7_2_mo.webp",
    "img_bg_sec7_3_mo.webp"
];


const loungeImg = [
    'img_sec10_01_mo.png',
    'img_sec10_02_mo.webp',
    'img_sec10_03_mo.png',
]

const colorList = [
    { name: 'Lexus black', code: '#000000', class: 'black'},
    { name: 'Electrified blue', code: '#3366CC', class: 'blue'},
    { name: 'gray', code: '#B9C0C2', class: 'gray'},
    { name: 'light gray', code: '#F3F4F4', class: 'light-gray'},
    { name: 'white', code: '#FFFFFF', class: 'white'}
]


export default function Lexus() {
    const wdp = workDetailPath();
    const wp = wdp + 'lexus/';
    const [thumbsSwiper, setThumbSwiper] = useState([]);

    const handleSetThumbSwiper = (swiper) => {
        setThumbSwiper((prev) => [...prev, swiper]);
    }


    return (
        <div className="work-detail-wrap lexus_new">
            <div className="project-cover">
                <div className={`cover-img-wrap`}>
                    <Fadeup className='phone-wrap'>
                        <img src={wp + 'img_intro_mo.webp'} />
                    </Fadeup>
                </div>

                <div className="cover-text-wrap">
                    <div className='award-mark'>
                        <img src={wp + 'img_award_lexus.webp'} />
                    </div>
                    <div className="container">
                        <h2 className="project-type">TOYOTA Korea</h2>
                        <h4 className="project-name">LEXUS LOUNGE</h4>
                        <ul className="project-team">
                            <li className="team-tag">design</li>
                            <li className="team-tag">Project Management</li>
                            <li className="team-tag">development</li>
                        </ul>
                        <Fadeup className="project-desc">
                            <p>
                                렉서스 라운지는 고객 선호도와 차량 관리 주기에 기반하여 차별화된 차량 관리 경험과 멤버십 혜택을 제공하는 서비스입니다.
                                <br />
                                <br />
                                스마트 차량 관리를 통해 차량 정비 내역과 전자 문서 등 차량 히스토리를 손쉽게 확인할 수 있으며, 앱에서 서비스 센터 예약도 가능합니다. 공동 사용자 등록 기능으로 실소유자 외에도 가족 및 법인 사용자와 혜택을 공유하며 차량 관리를 함께할 수 있습니다. 2024년 개편된 렉서스 라운지는 새로운 AMAZING MEMBERS 등급에 따라 맞춤형 콘텐츠와 특별한 혜택을 제공합니다. 제주도 시승 예약과 같은 이벤트 참여 기회, 서비스 센터 입고 시 실시간 공정 상태 확인, 푸시 알림 등 다양한 편의 기능을 제공합니다.
                                <br />
                                <br />
                                뿐만 아니라 고객의 차량 생애 주기를 파악해 정기 점검 알림과 개인화 콘텐츠를 제공하며, 렉서스 브랜드 신뢰도를 높이는 타깃 마케팅을 지원합니다.
                            </p>
                        </Fadeup>
                    </div>
                </div>
            </div>

            <div className="project-contents">
                <section className="section-01">
                    <div className='container'>
                        <div className="img-bg">
                            <img src={wp + 'img_bg_sec1_mo.webp'} />
                        </div>
                        <Fadeup className="content-wrap">
                            <div 
                            className={`container-desc`}
                            >
                                <h3 className='desc-tit'>project overview</h3>
                                <p className='desc-text'>
                                렉서스 라운지는 고객에 대한 공감과 이해를 바탕으로 긍정적 고객경험을 전하며 신뢰 관계를 형성해 왔습니다. 하지만 우리는 지난 관계에 정체되지 않고, 장기적으로 지속할 수 있는 관계를 구축하기 위한 발판의 필요성을 느꼈습니다. 
                                <br/>
                                <br/>
                                고객이 계속해서 서비스에 찾아오며 가치를 경험하고 머무를 수 있도록 이전 서비스가 가진 강점을 더욱 강화하고, 고객들의 라이프스타일에 대한 이해를 바탕으로 맞춤형 콘텐츠와 서비스를 제안하며 더욱 가치있는 경험을 전달하고자 합니다.
                                <br/>
                                <br/>
                                고객을 위해 정체되지 않고 끊임없이 진보하는 렉서스 라운지는, 고객경험을 계속해서 향상시키며 고객과의 관계를 지속하려 합니다.
                                </p>
                            </div>
                            
                        </Fadeup>
                        <div className={`benefit-wrap`}
                                >
                                <Fadeup className='benefit-list'>
                                    <sub>(1)</sub>
                                    <strong>advanced<br/>
                                    SERVICE</strong>
                                    <p>기존의 서비스가 가진 강점을 더욱 강화하고</p>
                                </Fadeup>
                                <Fadeup className='benefit-list'>
                                    <sub>(2)</sub>
                                    <strong>better<br/>
                                    experience</strong>
                                    <p>더 나은 가치 경험 환경을 제공하여</p>
                                </Fadeup>
                                <Fadeup className='benefit-list'>
                                    <sub>(3)</sub>
                                    <strong>lasting<br/>
                                    relationship</strong>
                                    <p>고객과의 관계를 지속할 수 있도록</p>
                                </Fadeup>
                        </div>
                    </div>
                    
                </section>

                <section className="section-02">
                    <motion.div className='text-wrap'>
                        <motion.div
                            initial="hidden"
                            whileInView="visible"
                            viewport={{once: true}} 
                            className='ml-auto'>
                            <motion.p
                            variants={{
                                hidden: {
                                    opacity: 0,
                                    x: '80px',
                                    transition: {
                                        duration: 0.4,
                                        delay: 0
                                    }
                                },
                                visible: {
                                    opacity: 1,
                                    x:'0px',
                                    transition: {
                                        duration: 0.5,
                                        delay: 0,
                                        ease: 'easeInOut'
                                    }
                                }
                            }}
                           >lexus</motion.p>
                        </motion.div>
                        <motion.div
                            initial="hidden"
                            whileInView="visible"
                            viewport={{once: true}}>
                            <motion.p
                                className='to-right'
                                variants={{
                                    hidden: {
                                        opacity: 0,
                                        x: '-80px',
                                        transition: {
                                            duration: 0.4,
                                            delay: 0
                                        }
                                    },
                                    visible: {
                                        opacity: 1,
                                        x:'0px',
                                        transition: {
                                            duration: 0.5,
                                            delay: 0,
                                            ease: 'easeInOut'
                                        }
                                    }
                                }}
                            >owner</motion.p>
                        </motion.div>
                        
                    </motion.div>
                    <div className="img-01">
                        <img src={wp + 'img_sec4_mo.webp'} />
                    </div>

                </section>

                <section className="section-03">
                    <Fadeup delay={1.5}>
                        <div className='container-desc'>
                            <h3 className='desc-tit'>strategy</h3>
                            <p className='desc-text'>
                            먼저 우리는 렉서스 라운지를 이용하는 고객에 대한 의미를 재정의 함으로서, 장기적인 관계 형성을 위해 나아가야 할 방향성을 설정하였습니다. 모든 고객을 대상으로 한 ‘서비스 제공자'와 ‘서비스 이용자'의 관계에서 벗어나, 이전보다 더욱 친밀하고 단단한 관계로 발전시켜 장기적 관계를 형성할 수 있는 발판을 마련하려 합니다.
                            <br/>
                            <br/>
                            그래서 우리는 고객을 단순히 ‘서비스 이용자'가 아닌 ‘렉서스 오너'로 바라보며 오너의 라이프스타일에 대한 공감과 이해를 바탕으로 개개인에 맞춘 서비스와 콘텐츠를 더욱 강화하고자 합니다.
                            </p>
                        </div>
                    </Fadeup>
                </section>

                <section className="section-04">
                    <div className='tit-wrap'>
                        <Typography
                            className="text-en strong"
                            txt="pride"
                        />
                        <Typography
                            className="text-en small"
                            txt="of the"
                        />
                        <Typography
                            className="text-en strong"
                            txt="owner"
                        />
                    </div>
                    <div className='desc-wrap'>
                        <p>
                            렉서스 오너만이 경험할 수 있는 렉서스 라운지의<br />
                            프리미엄 서비스와 혜택들은, 곧 오너의 자부심이<br /> 
                            되어 그들의 삶에 녹아듭니다.
                        </p>
                        <br/>
                        <br/>
                        <p>
                            자부심을 통해 느낄 수 있는 가치는 렉서스 오너와<br />
                            렉서스 라운지의 연결고리를 더욱<br />
                            단단하게 성장시키는 원동력이 됩니다.
                        </p>

                    </div>
                    <div className='gradient-rectangle'></div>
                    
                </section>

                <section className="section-05">
                    <div className='container'>
                        <div
                            className='content-wrap'>
                            <div className='bg-wrap'>
                                <Swiper className='bg-swiper'
                                onSwiper={handleSetThumbSwiper}
                                modules={[EffectFade, Thumbs]}
                                    effect='fade'
                                    allowTouchMove={false}
                                    fadeEffect={{ crossFade: true }}
                                >
                                    {
                                        bgImages.map((img, idx) => {
                                        return (
                                            <SwiperSlide 
                                            key={`thumb01_img_${idx}`}>
                                                <img 
                                                    src={wp + img}
                                                />
                                            </SwiperSlide>
                                        )
                                    })
                                    }
                                </Swiper>
                            </div>
                            <div className='desc desc-01'>
                                <Swiper
                                    className='thumb-swiper'
                                    onSwiper={handleSetThumbSwiper}
                                    modules={[EffectFade, Thumbs]}
                                    effect='fade'
                                    allowTouchMove={false}
                                    fadeEffect={{ crossFade: true }}>
                                        {
                                            images.map((img, idx) => {
                                            return (
                                                <SwiperSlide 
                                                key={`thumb01_img_${idx}`}>
                                                    <img 
                                                        src={wp + img.topThumb}
                                                    />
                                                </SwiperSlide>
                                            )
                                        })
                                        }
                                </Swiper>
                            </div>
                            <div className='img-wrap'>
                                <Swiper 
                                slidesPerView={'auto'}
                                centeredSlides={true}
                                freeMode={true}
                                spaceBetween={20}
                                thumbs={{ swiper: thumbsSwiper[0]}}
                                modules={[Thumbs]}
                                onSlideChange={(swiper) => {
                                    thumbsSwiper.forEach((thumb) => thumb.slideTo(swiper.activeIndex));
                                }}
                                >
                                    {
                                        images.map((img, idx) => {
                                            return (
                                                <SwiperSlide
                                                    key={`img_${idx}`}>
                                                    <img 
                                                    className={`img`}
                                                    src ={wp + img.main}
                                                    alt={`렉셔스 이미지 ${idx + 1}`}
                                                />
                                                </SwiperSlide>
                                            )
                                        })
                                    }
                                </Swiper>
                            <span className='line'></span>
                            </div>
                            
                            <div className='desc desc-02'>
                                <Swiper
                                    className='thumb-swiper'
                                    onSwiper={handleSetThumbSwiper}
                                    modules={[EffectFade, Thumbs]}
                                    effect='fade'
                                    allowTouchMove={false}
                                    fadeEffect={{ crossFade: true }}>
                                    {
                                        images.map((img, idx) => {
                                            return (
                                                <SwiperSlide 
                                                key={`thumb02_img_${idx}`}>
                                                    <img 
                                                        src={wp + img.bottomThumb}
                                                    />
                                                </SwiperSlide>
                                            )
                                        })
                                    }
                                </Swiper>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="section-06">
                    <Fadeup>
                        <div className='container-desc'>
                            <h3 className='desc-tit'>design elements</h3>
                            <p className='desc-text'>
                            렉서스 브랜드가 가진 아이덴티티에 렉서스 라운지만의 아이덴티티를 더한 이미지를 담았습니다. 렉서스 브랜드 로고에 담긴 쉐입을 적용한 아이콘과 브랜드 폰트를 통해 기존의 헤리티지를 담고, 렉서스 전동화를 상징하는 블루 컬러를 렉서스 라운지만의 컬러로 재해석하여 활용했습니다.
                            </p>
                        </div>
                    </Fadeup>
                    <Fadeup className="img-01" delay={0.5}>
                        <img src={wp + "img_sec8_01_mo.webp"} />
                    </Fadeup>
                    <Fadeup className="img-02" delay={0.8}>
                        <img src={wp + "img_sec8_02_mo.webp"} />
                    </Fadeup>
                    <Fadeup className="img-03" delay={0.5}>
                        <img src={wp + "img_sec8_03_mo.webp"} />
                    </Fadeup>
                    <ul className='color-list-wrap'>
                        {
                            colorList.map((list, idx) => {
                                return (
                                    <li 
                                    key={list + idx
                                    }
                                    className={`color-list ${list.class}`}>
                                        <span className='color-name'>
                                            {list.name}
                                        </span>
                                        <span className='color-code'> {list.code}</span>
                                    </li>
                                )
                            })
                        }
                    </ul>
                </section>

                <section className="section-07">
                    <div className='container'>
                        <Fadeup>
                            <strong className='gradient-tit'>
                                personalized<br/>
                                experience
                            </strong>
                        </Fadeup>
                        <Fadeup className="img-01">
                            <img src={wp + "img_sec9_mo.webp"} />
                        </Fadeup>
                    </div>
                </section>

                <section className="section-08">
                    <Fadeup className="tit-wrap">
                        <h5>Lounge</h5>
                        <p>
                        렉서스 라운지와 처음 마주하는 라운지에는 오너의 멤버십 등급에 맞춰진 UI가 적용되며, 오너 개개인의 사용 환경에 맞춰진 콘텐츠를 제공합니다. 오너가 놓치지 말아야 할 혜택을 먼저 제안하며, 오너가 더욱 가치있는 경험을 누릴 수 있도록 합니다.
                        </p>
                    </Fadeup>

                    <Fadeup endClass className="img-01" >
                        {
                            loungeImg.map((img, idx) => {
                                return (
                                    <div key={idx} className={`img-wrap img-${idx+1}`}>
                                        <img 
                                            className={`lounge-img`}
                                            src={wp + img}
                                            alt={`라운지 이미지 ${idx}`}
                                        />
                                    </div>
                                )
                            })
                        }
                        <div className='img-02'>
                            <div className='video-wrap'>
                                <div className='cont-img-mockup'></div>
                                <div className='cont-img-scrollview'>
                                    <img
                                        src={wp + 'img_sec10_view_mo.webp'}

                                    /> 
                                </div>
                            </div>
                        </div>
                        
                    </Fadeup>
                </section>
                <section className='section-09'>
                    <div className='container'>
                        <Fadeup>
                            <strong className='gradient-tit'>
                                exclusive<br/>
                                benefits
                            </strong>
                        </Fadeup>
                        <Fadeup className="img-01">
                            <img src={wp + "img_sec11_mo.webp"} />
                        </Fadeup>
                    </div>
                </section>
                <section className='section-10'>
                    <div className='container'>
                        <Fadeup>
                            <h5 className='tit'>
                                membership
                            </h5>
                        </Fadeup>
                        <Fadeup className={'img-fade'} endClass long={true} >
                            <div className='img-box'>
                                <div className='img-wrap'>
                                    <div className='cont-img-mockup'></div>
                                    <div className='cont-img-scrollview'>
                                        <img
                                            src={wp + 'img_sec12_view_mo.webp'}

                                        /> 
                                    </div>
                                </div>
                            </div>
                        </Fadeup>
                        <Fadeup>
                            <p className='desc-text'>
                                렉서스 오너만을 위해 준비된 다양한 카테고리의
                                멤버십 혜택들을 한 화면에 담았습니다. 프리미엄
                                혜택, 스탬프 리워드, 멤버십 캠페인으로 구성된
                                혜택은 취향에 맞춰 자유롭게 이용할 수 있습니다.
                            </p>
                        </Fadeup>
                    </div>
                    
                </section>
                <section className='section-11'>
                    <div className='container'>
                        <Fadeup>
                            <strong className='gradient-tit'>
                                exclusive<br/>
                                car care
                            </strong>
                        </Fadeup>
                        <Fadeup endClass className="vid-box">
                            <div className='video-wrap'>
                                <div className='cont-img-mockup'></div>
                                    <div className='cont-img-scrollview'>
                                        <img
                                            src={wp + 'img_sec13_view_mo.webp'}

                                        /> 
                                    </div>
                            </div>
                        </Fadeup>
                        <Fadeup className='img-box'>
                            <div className='img-wrap'>
                                <img src={wp + "img_sec13_02_mo.webp"} />
                            </div>
                        </Fadeup>
                        <Fadeup
                        delay={1}>
                            <div className='desc-text'>
                                마이카 페이지는 렉서스 오너의 스마트한 차량 관리를 돕기 위해 차량과 관련된 모든 콘텐츠를 담고 있으며,
                                하단에 고정된 ‘서비스 예약’ 버튼을 통해 차량 정비를 위해 쉽고 빠르게 서비스 센터를 예약할 수 있습니다.
                                <br/>
                                <br/>
                                많은 콘텐츠 사이에서 필요로 하는 정보를 빠르게 파악할 수 있도록 카드 UI를 통해 정보를 그룹핑 하였으며,<br/>
                                넓은 여백과 그림자를 적용하여 시원한 공간감으로 카드 UI를 시각적으로 드러냄과 동시에 복잡도를 낮췄습니다.
                            </div>
                        </Fadeup>
                    </div>
                </section>
                <section className='section-12'>
                    <Fadeup>
                        <img src={wp + "img_sec14_mo.webp"} />
                    </Fadeup>
                </section>
            </div>
        </div>
    );
}
