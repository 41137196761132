import { useEffect, useRef, useState } from 'react';
import WorkList from '../WorkList';
import { motion, useScroll, useTransform } from 'framer-motion';
import Typography from '../Typography';
import LinkWithTransition from '../LinkWithTransition';

export default function MainSectionWork() {
    const sectionWorkRef = useRef();
    const horizontalScrollRef = useRef();

    const { scrollYProgress } = useScroll({
        target: sectionWorkRef
    });
    const [scrollWidth, setScrollWidth] = useState(0);

    const x = useTransform(
        scrollYProgress,
        [0, 1],
        ['0px', (scrollWidth - document.body.clientWidth) * -1 + 'px']
    );

    function setWorkSlide() {
        // work > 가로 스크롤 영역 높이 설정
        const slideWidth = document.querySelector('.work-list li').offsetWidth;
        if (horizontalScrollRef) {
            sectionWorkRef.current.style.height =
                horizontalScrollRef.current.scrollWidth - slideWidth / 2 + 'px';
            setScrollWidth(horizontalScrollRef.current.scrollWidth);
        }
    }
    useEffect(() => {
        setWorkSlide();
        window.addEventListener('resize', setWorkSlide);

        return () => {
            window.removeEventListener('resize', setWorkSlide);
        };
    }, []);

    return (
        <section className="MainSectionWork" ref={sectionWorkRef}>
            <div className="sticky">
                <h3>
                    <Typography txt="work Archive"></Typography>
                </h3>
                <div className="horizontal-scroll" ref={horizontalScrollRef}>
                    <motion.ul
                        className="work-list"
                        style={{
                            x: x
                        }}>
                        <WorkList
                            project="lexus"
                            title="렉서스 라운지"
                            subTitle="LEXUS LOUNGE (TOYOTA Korea)"
                            dataTag="렉서스 고객을 위한 어플리케이션"
                            img={process.env.PUBLIC_URL + '/image/img_work_thumb_lexus_award.webp'}
                        />
                        <WorkList
                            project="shinsegae"
                            title="신세계 쇼핑 앱"
                            subTitle={<>SHINSEGAE SHOPPING<span> (Shinsegae Live Shopping)</span></>}
                            dataTag="쇼핑의 신세계가 펼쳐지다!"
                            img={process.env.PUBLIC_URL + '/image/img_work_thumb_shinsegae_award.webp'}
                        />
                        <WorkList
                            project="archiveat"
                            title="아카이빗"
                            subTitle="ARCHIVEAT (Public Kitchen)"
                            dataTag="레시피 큐레이션의 모든 것"
                            img={process.env.PUBLIC_URL + '/image/img_work_thumb_archiveat_award.webp'}
                        />
                        <WorkList
                            project="ekolon"
                            title="이코오롱 멤버십"
                            subTitle="e-KOLON Membership (Kolon Industries)"
                            dataTag="패션하우스 KOLON의 모든 혜택을 한 곳에서!"
                            img={process.env.PUBLIC_URL + '/image/img_work_thumb_ekolon.webp'}
                        />
                        <WorkList
                            project="bentley"
                            title="벤틀리 멤버십"
                            subTitle="Bentley Membership (Bentley Seoul)"
                            dataTag="벤틀리 서울 출고 고객만을 위한 멤버십 서비스"
                            img={process.env.PUBLIC_URL + '/image/img_work_thumb_bentley_award.webp'}
                        />
                        <WorkList
                            project="ddb"
                            title="달다방"
                            subTitle=" Daldabang (Yuhan Kimberly)"
                            dataTag="매달 찾아오는 생리공감 카페"
                            img={process.env.PUBLIC_URL + '/image/img_work_thumb_ddb_award.webp'}
                        />
                    </motion.ul>
                </div>

                <LinkWithTransition to="/work" className="btn-view-all">
                    view all project
                </LinkWithTransition>
            </div>
        </section>
    );
}
