import { animate, easeInOut, motion, stagger, useInView, useMotionValueEvent, useScroll, useTransform } from 'framer-motion';
import { useEffect, useRef, useState } from 'react';
import useViewport from '../../hook/useViewport';
import Typography from '../Typography';

export default function SectionDestination() {
    const sectionRef = useRef();
    const typoRef = useRef();
    const stickyRef = useRef();
    const isInView = useInView(sectionRef, {
        margin: "900px 0px 0px 0px"
    });
    const isInViewTypo = useInView(typoRef);

    const { scrollYProgress } = useScroll({
        target: sectionRef,
        offset: ["start start", "end end"]
    });
    const {viewportW} = useViewport();

    const [typoWidth, setTypoWidth] = useState();

    const x = useTransform(scrollYProgress, [0, 1], ['0px', (viewportW - typoWidth)+ "px"], { ease: easeInOut });


    useEffect(() => {
        if(typoRef) {
            setTypoWidth(typoRef.current.clientWidth);
        }
    },[typoRef]);
    useEffect(() => {
        if(isInViewTypo) {
            animate(
                '.tag',
                {
                    y: ['500%', '0%']
                },
                {
                    type: 'spring',
                    stiffness: 150,
                    delay: stagger(0.1, { from: 'center' })
                }
            );
            animate(
                '.tag span',
                {
                    opacity: [0, 1]
                },
                {
                    duration: 0.2
                }
            );
        }
    },[isInViewTypo]);


    return (
        <div className={`SolutionSectionDestination${isInView ? ' black' : ''}`} ref={sectionRef}>
            <div className={`sticky`} ref={stickyRef}>
                <motion.div ref={typoRef} className="scroll-text" style={{ x: x }}>
                    here is <br />
                    destination
                    <div className="tag handly">
                        <span>HANDLY</span>
                    </div>
                    <div className="tag popee">
                        <span>POPEE</span>
                    </div>
                    <div className="tag mentor">
                        <span>MENTOR</span>
                    </div>
                </motion.div>
            </div>
            <div className="msg-wrap">
                <div className="msg-en">
                    <Typography txt="Our solution is right here," />
                    <Typography txt="so go for it!" />
                </div>
                <div className="msg-ko">
                    <Typography className="right" txt="이 지속 가능한 솔루션들이 여러분의 비전을" />
                    <Typography txt="실현할 수 있도록 길잡이가 되어 드릴게요." />
                    <Typography txt="앱노트는 더 나은 세상을 만들어가는 여러분들과" />
                    <Typography className="right" txt="함께할 날을 기다리고 있습니다." />
                </div>
            </div>
        </div>
    );
}
