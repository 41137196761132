import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCreative, EffectCards } from 'swiper';
import { Autoplay } from 'swiper';
import 'swiper/css';
import { GnbContext } from '../Pc';
import { useEffect, useContext, useRef, useState } from 'react';
import { motion, useInView, useTransform, useMotionValueEvent, useScroll, calcLength} from 'framer-motion';
import Typography from '../Typography';


// text 정의
const desc01Text = [
    { h5: 'core value 1', strong: '오너 개개인의 다양한<br/>라이프스타일을 고려한'},
    { h5: 'core value 2', strong: '신뢰할 수 있고 전문적인<br/>서비스를 제공하는'},
    { h5: 'core value 3', strong: '이전보다 더 나은 삶을<br/>향해 함께 나아가는'},
]

const desc02Text = [
    { h5: 'Design Principle 1', strong: 'Various & Flexible', p: '오너 개개인을 위한 다채로운 콘텐츠와 서비스를 있는 그대로 담아낼 수 있도록<br/>와이드한 이미지를 적용하고, 글래스모피즘을 통해 다채로움을 유연하게 담아냅니다.'},
    { h5: 'Design Principle 2', strong: 'Intuitive & Easy', p: '스마트한 차량 관리를 위한 많은 서비스와 정보 속에서 필요한것을<br/>쉽고 빠르게 찾아 이용할 수 있도록 카드 UI를 활용해 정보 위계를 부여합니다'},
    { h5: 'Design Principle 3', strong: 'Lively & Futuristic', p: '기존 렉서스 라운지의 정적인 이미지에서 벗어나 전동화를 상징하는 블루 컬러를 더하고,<br/>그림자와 그라디언트를 통해 깊이감을 부여하여 진보하는 미래지향적 이미지를 전합니다.'},
]

const bgImages = [
    "url('/site/res/image/lexus/img_lexus_blur_layer_01.webp')",
    "url('/site/res/image/lexus/img_lexus_blur_layer_02.webp')",
    "url('/site/res/image/lexus/img_lexus_blur_layer_03.webp')",
];

const images = [
    {
        src: "/site/res/image/lexus/img_lexus_sec7_1_pc.webp",
    },
    {
        src: "/site/res/image/lexus/img_lexus_sec7_2_pc.webp",
    },
    {
        src: "/site/res/image/lexus/img_lexus_sec7_3_pc.webp",
    },
  ];

export default function Lexus() {
    const viewRef = useRef();
    const imgRef = useRef();
    const clipRef = useRef();
    const stickyRef = useRef();
    const scrollImgRef01 = useRef();
    const scrollImgRef02 = useRef();
    const scrollImgRef03 = useRef();

    const isInView = useInView(viewRef, { once : true });
    const isInViewImg = useInView(imgRef);
 
    const gnbContext = useContext(GnbContext);
    const [newClip, setClip] = useState(30);
    const [newTextSize, setnewTextSize] = useState(8.75);
    const [newTextOp, setnewTextOp] = useState(1);
    const [newX, setNewX] = useState(50);
    const [isShow, setIsShow] = useState(false);
    const variants = {
        visible: {
            opacity: 1,
            y: '0%',
            transition: {
                opacity: {
                    duration: 1.8
                },
                y: {
                    duration: 1.2
                },
                duration: 1.2,
                delay: 0.05,
                ease: 'linear'
            }
        },
        hidden: { opacity: 0, y: '50%' }
    };

    const variantsRight = {
        visible: {
            opacity: 1,
            x: '0px',
            transition: {
                opacity: {
                    duration: 1.2
                },
                y: {
                    duration: 0.8
                },
                duration: 0.8,
                delay: 0.05,
                ease: 'linear'
            }
        },
        hidden: { opacity: 0, x: '-50px' }
    }

    const variantsLeft = {
        visible: {
            opacity: 1,
            x: '0px',
            transition: {
                opacity: {
                    duration: 1.2
                },
                y: {
                    duration: 0.8
                },
                duration: 0.8,
                delay: 0.05,
                ease: 'linear'
            }
        },
        hidden: { opacity: 0, x: '50px' }
    }

    // clip path
    const { scrollYProgress, scrollY } = useScroll();
    const { scrollYProgress: imgRefScroll} = useScroll({
        target: clipRef,
        offset: ["start start", "end end"]
    });

    const clip = useTransform(imgRefScroll, [0.2, 0.6], [30, 0]);
    const textSize = useTransform(imgRefScroll, [0, 0.2], [8.75, 5]);
    const textOpacity = useTransform(imgRefScroll, [0, 0.2], [1, 0.3]);


    // img opacity
    const imgRefOp = useTransform(imgRefScroll, [0.2, 0.6], [1, 0.4]);
    // text transform X
    const textX = useTransform(imgRefScroll, [0.6, 1], [50, 0]);
    
    // container 02 이미지 clip
    useMotionValueEvent(imgRefScroll, 'change', (latest) => {
        if (isInViewImg) {
            setClip(clip.current);
            setnewTextSize(textSize.current);
            setnewTextOp(textOpacity.current);
        } else {
            setClip(30);
            setnewTextSize(8.75);
            setnewTextOp(1);
        }
    });
    // container 02 텍스트 등장
    
    useMotionValueEvent(textX, 'change', (v) => {
        setNewX(textX.current);
        if (v.toFixed(1) <= 48) {
            setIsShow(true);
        } else {
            setIsShow(false);
        }
    });

    // container 05 화면 전환
    const [activeStep, setActiveStep] = useState(0);
    const [shouldAnimate, setShouldAnimate] = useState([]);
    const { scrollYProgress: stickyScrollYProgress } = useScroll({
    target: stickyRef,
    offset: ["start start", "end end"]
    });
    
    const isAnimating = useRef(false);
    const isFirstRender = useRef(false);

    useMotionValueEvent(stickyScrollYProgress, 'change', (latest) => {
        const rounded = parseFloat(latest.toFixed(1));
        if (isAnimating.current) return;
        if (rounded > 0.6) { 
            setActiveStep(2);
        }
        else if (rounded > 0.3) { 
            isFirstRender.current = true;
            setActiveStep(1);

        }
        else setActiveStep(0);
    });


// Update shouldAnimate array
    useEffect(() => {
        const timeout = setTimeout(() => {
            const updatedShouldAnimate = images.map((_, idx) => activeStep > idx);
            setShouldAnimate(updatedShouldAnimate);
        }, 50);

        return () => clearTimeout(timeout);
    }, [activeStep]);
    
    

    // gnb 변경

    useEffect(() => {
        gnbContext.actions.setGnbColor('white');
    }, []);
    return (
        <div className="Lexus">
            <div className="work-detail-wrap work-detail-container" data-hash="lexus_new">
                <div className="project-cover" ref={viewRef}>
                    <div className={`cover-img-wrap ${isInView && 'on'}`}>
                        <div className='phone-wrap'>
                            <img src={
                                process.env.PUBLIC_URL +
                                '/site/res/image/lexus/img_lexus_sec1_pc.webp'}></img>
                        </div>
                    </div>
                    <div className="cover-text-wrap">
                        <div className='award-mark'>
                            <img
                                    src={
                                        process.env.PUBLIC_URL +
                                        '/site/res/image/img_award_lexus.webp'
                            }></img>
                        </div>
                        <div className="container">
                            <h2 className="project-type">TOYOTA Korea</h2>
                            <h4 className="project-name">LEXUS LOUNGE</h4>
                            <ul className="project-team">
                                <li className="team-tag">design</li>
                                <li className="team-tag">Project Management</li>
                                <li className="team-tag">development</li>
                            </ul>
                            <motion.div
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true }}
                                className="project-desc">
                                <motion.p variants={variants}>
                                렉서스 라운지는 고객 선호도와 차량 관리 주기에 기반하여 차별화된 차량 관리 경험과 멤버십 혜택을 제공하는 서비스입니다. 
                                    <br />
                                    <br />
                                    스마트 차량 관리를 통해 차량 정비 내역과 전자 문서 등 차량 히스토리를 손쉽게 확인할 수 있으며,<br /> 앱에서 서비스 센터 예약도 가능합니다. 공동 사용자 등록 기능으로 실소유자 외에도 가족 및 법인 사용자와 혜택을 공유하며 차량 관리를 함께할 수 있습니다. 2024년 개편된 렉서스 라운지는 새로운 AMAZING MEMBERS 등급에 따라 맞춤형 콘텐츠와 특별한 혜택을 제공합니다. 제주도 시승 예약과 같은 이벤트 <br /> 참여 기회, 서비스 센터 입고 시 실시간 공정 상태 확인, 푸시 알림 등 다양한 편의 기능을 제공합니다.
                                    <br />
                                    <br />
                                    뿐만 아니라 고객의 차량 생애 주기를 파악해 정기 점검 알림과 개인화 콘텐츠를 제공하며, 렉서스 브랜드 신뢰도를 높이는 타깃 마케팅을 지원합니다.
                                    </motion.p>
                            </motion.div>
                        </div>
                    </div>
                </div>
                <div className="work-detail-contents">
                    <section className="work-detail-information">
                        <div className="work-detail-information-container work-detail-information-container-01">
                            <div className='container-desc'>
                                <h3 className='desc-tit'>project overview</h3>
                                <p className='desc-text'>
                                렉서스 라운지는 고객에 대한 공감과 이해를 바탕으로 긍정적 고객경험을 전하며 신뢰 관계를 형성해 왔습니다. 하지만 우리는 지난 관계에 정체되지 않고, 장기적으로 지속할 수 있는 관계를 구축하기 위한 발판의 필요성을 느꼈습니다. 고객이 계속해서 서비스에 찾아오며 가치를 경험하고 머무를 수 있도록 <br/>이전 서비스가 가진 강점을 더욱 강화하고, 고객들의 라이프스타일에 대한 이해를 바탕으로 맞춤형 콘텐츠와 서비스를 제안하며 더욱 가치있는 경험을 <br/> 전달하고자 합니다. 고객을 위해 정체되지 않고 끊임없이 진보하는 렉서스 라운지는, 고객경험을 계속해서 향상시키며 고객과의 관계를 지속하려 합니다.
                                </p>
                            </div>
                            <ul className='point-wrap'>
                                <li className='point-list'>
                                    <div className='ml-auto'>
                                        <strong>advanced</strong>
                                        <sub>(1)</sub>
                                    </div>
                                    <motion.div
                                        initial="hidden"
                                        whileInView="visible"
                                        viewport={{ once: true }}>
                                        <motion.div variants={variantsRight}>
                                            <strong>service</strong>
                                            <span className='desc_01'>기존의 서비스가 가진 강점을 더욱 강화하고</span>
                                        </motion.div>
                                    </motion.div>
                                </li>
                                <li className='point-list'>
                                    <div className='ml-auto'>
                                        <sub className='sub_02'>(2)</sub>
                                        <strong>better</strong>
                                    </div>
                                    <motion.div
                                        initial="hidden"
                                        whileInView="visible"
                                        viewport={{ once: true }}>
                                        <motion.div
                                        variants = {variantsLeft}>
                                            <strong>experience</strong>
                                            <span className='desc_02'>더 나은 가치 경험 환경을 제공하여</span>
                                        </motion.div>
                                    </motion.div>
                                </li>
                                <li className='point-list'>
                                    <div>
                                        <strong>lasting</strong>
                                        <sub>(3)</sub>
                                    </div>
                                    <motion.div
                                        initial="hidden"
                                        whileInView="visible"
                                        viewport={{ once: true }}>
                                        <motion.div variants={variantsRight}>
                                            <span className='desc_03'>고객과의 관계를 지속할 수 있도록</span>
                                            <strong>relationship</strong>
                                        </motion.div>
                                    </motion.div>
                                    
                                </li>
                            </ul>
                        </div>

                        <div className="work-detail-information-container work-detail-information-container-02"
                            ref={clipRef}>
                            <div className='tit-wrap'>
                                <div
                                className={`${textSize.current < 5.3 ? 'show-line' : ''}`}
                                >
                                    <motion.h3
                                    style={{
                                    fontSize: newTextSize + 'rem',
                                    opacity: newTextOp}}
                                    >
                                    membership
                                    </motion.h3>
                                    <span className='line'></span>
                                </div>
                                <div
                                className={`${textSize.current < 5.3 ? 'show-line' : ''}`}>
                                    <motion.h3
                                        style={{
                                        fontSize: newTextSize + 'rem',
                                        opacity: newTextOp}}>
                                        USER
                                    </motion.h3>
                                    <span className='line'></span>
                                </div>
                            </div>
                            <div className='sticky'>
                                <div className='img-box'>
                                    <motion.div 
                                        style={{
                                            clipPath: 'inset(0%' + newClip + '%' + newClip / 2 + '%)',
                                            opacity: imgRefOp
                                        }}
                                        transition={{
                                            ease: 'easeInOut'
                                        }}
                                        className='img-wrap'>
                                            <img 
                                            ref={imgRef}
                                            className='reactive-rectangle'
                                            src={
                                                process.env.PUBLIC_URL +
                                            '/site/res/image/lexus/img_lexus_bg_sec4_pc.webp'
                                            }
                                            alt="렉서스 이미지" />
                                    </motion.div>
                                    
                                    <div className={`text-wrap ${isShow && 'show'}`}>
                                        <motion.p className='lexus'
                                        style={{
                                            x: `-${newX}%`,
                                            }}
                                        >lexus</motion.p>
                                        <motion.p className='owner'
                                        style={{
                                            x: `${newX}%`
                                        }}>owner</motion.p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="work-detail-information-container work-detail-information-container-03">
                        <div className='container-desc'>
                                <h3 className='desc-tit'>strategy</h3>
                                <p className='desc-text'>
                                먼저 우리는 렉서스 라운지를 이용하는 고객에 대한 의미를 재정의 함으로서, 장기적인 관계 형성을 위해 나아가야 할 방향성을 설정하였습니다. 모든 고객을 대상으로 한 ‘서비스 제공자'와 ‘서비스 이용자'의 관계에서 벗어나, 이전보다 더욱 친밀하고 단단한 관계로 발전시켜 장기적 관계를 형성할 수 있는 발판을 마련하려 합니다.
                                <br/>
                                <br/>
                                그래서 우리는 고객을 단순히 ‘서비스 이용자'가 아닌 ‘렉서스 오너'로 바라보며 오너의 라이프스타일에 대한 공감과 이해를 바탕으로 개개인에 맞춘 서비스와 콘텐츠를 더욱 강화하고자 합니다.
                                </p>
                            </div>
                        </div>

                        <div className="work-detail-information-container work-detail-information-container-04">
                            <div className='img-bg'>
                                <img  
                                src={
                                    process.env.PUBLIC_URL +
                                '/site/res/image/lexus/img_lexus_bg_sec6_pc.webp'
                                }
                                alt="렉서스 이미지" />
                            </div>
                            <div className='tit-wrap'>
                                <Typography
                                    className="text-en strong"
                                    txt="pride"
                                />
                                <Typography
                                    className="text-en small"
                                    custom={1}
                                    txt="of the"
                                />
                                <Typography
                                    custom={2}
                                    className="text-en strong"
                                    txt="owner"
                                />
                            </div>
                            <div className='desc'>
                            렉서스 오너만이 경험할 수 있는 렉서스 라운지의 프리미엄 서비스와 혜택들은, 곧 오너의 자부심이 되어 그들의 삶에 녹아듭니다.<br/><br/>
                            자부심을 통해 느낄 수 있는 가치는 렉서스 오너와 렉서스 라운지의 연결고리를 더욱 단단하게 성장시키는 원동력이 됩니다.
                            </div>
                            <div className='blur'></div>
                            
                        </div>

                        <div className="work-detail-information-container work-detail-information-container-05"
                        ref={stickyRef}>
                            <motion.div className='sticky'
                            style={{ backgroundImage: bgImages[activeStep]}}
                            transition={{ duration: 0.5 }}>
                                <motion.div className='content-wrap' 
                                key={activeStep}
                                >
                                <div className='desc desc-01'>
                                    <motion.h5
                                        onAnimationStart={() => {
                                            if (isFirstRender.current) {
                                                isAnimating.current = true;
                                            }
                                        }}
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: 1 }}
                                        transition={{ duration: 1.5, ease: 'linear', delay: .3 }}
                                        dangerouslySetInnerHTML={{ __html: desc01Text[activeStep]?.h5 }}
                                    />
                                    <motion.strong
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: 1 }}
                                        transition={{ duration: 1.5 , ease: 'linear', delay: .3 }}
                                        dangerouslySetInnerHTML={{ __html: desc01Text[activeStep]?.strong }} />
                                    
                                </div>
                                <div className='img-wrap 
                                work-detail-information-img'>
                                
                                    {
                                        images.map((img, idx) => {
                                            return (
                                            <motion.img
                                            onTransitionEnd={() => { 
                                                isAnimating.current = false }}
                                            key={idx}
                                            className={`img ${shouldAnimate[idx] ? 'shouldAnimate' : ''}`}
                                            src={
                                            process.env.PUBLIC_URL +
                                                img.src
                                            }
                                            alt={`이미지 ${idx + 1}`}
                                            style={{
                                            position: idx === images.length - 1 ? "relative" : 
                                            "absolute",
                                        }}
                                        />
                                        )})
                                    }
                                    <span className='line'></span>
                                </div>
                                <div className='desc desc-02'>
                                    <motion.h5
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: 1 }}
                                        transition={{ duration: 1.5, ease: 'linear', delay: .3  }}
                                        dangerouslySetInnerHTML={{ __html: desc02Text[activeStep]?.h5 }}
                                    />
                                    <motion.strong
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: 1 }}
                                        transition={{ duration: 1.5, ease: 'linear', delay: .3  }}
                                        dangerouslySetInnerHTML={{ __html: desc02Text[activeStep]?.strong }}
                                    />
                                    <motion.p
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: 1 }}
                                        transition={{ duration: 1.5, ease: 'linear', delay: .3  }}
                                        dangerouslySetInnerHTML={{ __html: desc02Text[activeStep]?.p }}
                                    />

                                </div>
                                </motion.div>
                            </motion.div>
                        </div>

                        <div className="work-detail-information-container work-detail-information-container-06">
                                <div className='container-desc'>
                                <h3 className='desc-tit'>design elements</h3>
                                <p className='desc-text'>
                                렉서스 브랜드가 가진 아이덴티티에 렉서스 라운지만의 아이덴티티를 더한 이미지를 담았습니다. 렉서스 브랜드 로고에 담긴 쉐입을 적용한 아이콘과 브랜드 폰트를 통해 기존의 
                                <br/>
                                헤리티지를 담고, 렉서스 전동화를 상징하는 블루 컬러를 렉서스 라운지만의 컬러로 재해석하여 활용했습니다.
                                </p>
                            </div>
                            <ul className='logo-wrap'>
                                <li className='logo work-detail-information-img'>
                                <img  
                                    src={
                                    process.env.PUBLIC_URL +
                                    '/site/res/image/lexus/img_lexus_sec8_01_pc.webp'
                                    }
                                    alt="렉서스 이미지" />
                                </li>
                                <li className='mark work-detail-information-img'>
                                    <img  
                                    src={
                                        process.env.PUBLIC_URL +
                                    '/site/res/image/lexus/img_lexus_sec8_02_pc.webp'
                                    }
                                    alt="렉서스 이미지" />
                                </li>
                            </ul>
                            <div className='icon-box work-detail-information-img'>
                            <img  
                                    src={
                                        process.env.PUBLIC_URL +
                                    '/site/res/image/lexus/img_lexus_sec8_03_pc.webp'
                                    }
                                    alt="렉서스 이미지" />
                            </div>
                            <ul className='color-list-wrap'>
                                    <li className='color-list black'>
                                        <span className='color-name'>
                                        Lexus black
                                        </span>
                                        <span className='color-code'>#000000</span>
                                    </li>
                                    <li className='color-list blue'>
                                        <span className='color-name'>
                                        Electrified blue
                                        </span>
                                        <span className='color-code'>#3366CC</span>
                                    </li>       
                                    <li className='color-list gray'>
                                     <span className='color-name'>
                                     gray
                                     </span>
                                     <span className='color-code'>#B9C0C2</span>
                                    </li>      
                                    <li className='color-list light-gray'>
                                        <span className='color-name'>
                                        light gray
                                        </span>
                                        <span className='color-code'>#F3F4F4</span>
                                    </li>
                                    <li className='color-list white'>
                                        <span className='color-name'>
                                        white
                                        </span>
                                        <span className='color-code'>#FFFFFF</span>
                                    </li>
                            </ul>
                        </div>

                        <div className="work-detail-information-container work-detail-information-container-07">
                            <div className="container-07-wrap">
                            <motion.div
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true }}
                                className="work-detail-information-img work-detail-information-img-01 fade-up">
                                <motion.img
                                    variants={variants}
                                    src={
                                        process.env.PUBLIC_URL +
                                        '/site/res/image/lexus/img_lexus_sec9_text.webp'
                                    }
                                    alt="렉서스 이미지"
                                />
                            </motion.div>
                            <motion.div
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true }}
                                className="work-detail-information-img work-detail-information-img-02 fade-up">
                                <motion.img
                                    variants={variants}
                                    src={
                                        process.env.PUBLIC_URL +
                                        '/site/res/image/lexus/img_lexus_sec9_pc.webp'
                                    }
                                    alt="렉서스 이미지"
                                />
                            </motion.div>
                            </div>                    
                        </div>

                        <div className="work-detail-information-container work-detail-information-container-08">
                            <motion.div
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true }}
                                className='text-wrap'
                                >
                                <motion.div variants={variants}
                                className='container-desc'>
                                    <h4>Lounge</h4>
                                    <p className='desc-text'>
                                    렉서스 라운지와 처음 마주하는 라운지에는 오너의 멤버십 등급에 맞춰진 UI가 적용되며,<br/>
                                    오너 개개인의 사용 환경에 맞춰진 콘텐츠를 제공합니다. 오너가 놓치지 말아야 할 혜택을<br/>먼저 제안하며, 오너가 더욱 가치있는 경험을 누릴 수 있도록 합니다.
                                    </p>
                                </motion.div>
                            </motion.div>
                            <motion.div
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true }}
                                className='work-detail-information-img work-detail-information-img-01 fade-up'
                                >
                                <motion.img
                                    variants={variants}
                                    src={
                                        process.env.PUBLIC_URL +
                                        '/site/res/image/lexus/img_lexus_sec10_01_pc.webp'
                                    }
                                    alt="렉서스 이미지"
                                /> 
                            </motion.div>
                            <motion.div
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true }}
                                className='work-detail-information-img work-detail-information-img-02 fade-up'
                                >
                                <motion.img
                                    variants={variants}
                                    src={
                                        process.env.PUBLIC_URL +
                                        '/site/res/image/lexus/img_lexus_sec10_02_pc.webp'
                                    }
                                    alt="렉서스 이미지"
                                /> 
                            </motion.div>
                            <motion.div
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true }}
                                className="work-detail-information-img-lottie fade-up">
                                <motion.div className="lottie-wrap"
                                variants={variants}
                                onAnimationComplete={() => {
                                    if (scrollImgRef01.current) {
                                        scrollImgRef01.current.classList.add('ani-on');
                                    }
                                    }}
                                >
                                    <div className='cont-img-mockup'>
                                    </div>
                                    <div className='cont-img-scrollview'>
                                        <img
                                        ref={scrollImgRef01}
                                        src={
                                        process.env.PUBLIC_URL +
                                        '/site/res/image/lexus/img_lexus_sec10_view_pc.webp'
                                    }
                                    alt="렉서스 이미지"
                                        ></img>
                                    </div>
                                </motion.div>
                                
                            </motion.div>
                            <motion.div
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true }}
                                className='work-detail-information-img work-detail-information-img-03 fade-up'
                                >
                                <motion.img
                                    variants={variants}
                                    src={
                                        process.env.PUBLIC_URL +
                                        '/site/res/image/lexus/img_lexus_sec10_03_pc.webp'
                                    }
                                    alt="렉서스 이미지"
                                /> 
                            </motion.div>
                        </div>
                        <div className="work-detail-information-container work-detail-information-container-09">
                            <div className='img-bg'>
                                <img  
                                    src={
                                        process.env.PUBLIC_URL +
                                    '/site/res/image/lexus/img_lexus_bg_sec11_pc.webp'
                                    }
                                    alt="렉서스 이미지" />
                            </div>
                            <motion.div
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true }}
                                className="work-detail-information-img work-detail-information-img-01 fade-up">
                                <motion.img
                                    variants={variants}
                                    src={
                                        process.env.PUBLIC_URL +
                                        '/site/res/image/lexus/img_lexus_exclusive_benefits.webp'
                                    }
                                    alt="렉서스 이미지"
                                />
                            </motion.div>
                            <motion.div
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true }}
                                className="work-detail-information-img work-detail-information-img-02 fade-up">
                                <motion.img
                                    variants={variants}
                                    src={
                                        process.env.PUBLIC_URL +
                                        '/site/res/image/lexus/img_lexus_sec11_pc.webp'
                                    }
                                    alt="렉서스 이미지"
                                />
                            </motion.div>
                        </div>
                        <div className="work-detail-information-container work-detail-information-container-10">
                            <div className='img-bg work-detail-information-img'>
                                <img  
                                    src={
                                        process.env.PUBLIC_URL +
                                    '/site/res/image/lexus/img_lexus_bg_sec12_pc.webp'
                                    }
                                    alt="렉서스 이미지" />
                            </div>
                            <motion.div
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true }}
                                className='content'
                                >
                                <motion.div variants={variants}
                                className='container-desc'
                                onAnimationComplete={() => {
                                    if (scrollImgRef02.current) {
                                        scrollImgRef02.current.classList.add('ani-on')
                                    }
                                } }>
                                    <h4>membership</h4>
                                    <div className='work-detail-information-img'>
                                        <div className='cont-img-mockup'>
                                        </div>
                                        <div className='cont-img-scrollview'>
                                            <img
                                            ref={scrollImgRef02}
                                            src={
                                            process.env.PUBLIC_URL +
                                            '/site/res/image/lexus/img_sec12_view_pc.webp'
                                        }
                                        alt="렉서스 이미지"
                                            ></img>
                                        </div>
                                    </div>
                                    <p className='desc-text'>
                                    렉서스 오너만을 위해 준비된 다양한 카테고리의 <br/>

                                    멤버십 혜택들을 한 화면에 담았습니다. 프리미엄<br/>
                                    혜택, 스탬프 리워드, 멤버십 캠페인으로 구성된 <br/>
                                    혜택은 취향에 맞춰 자유롭게 이용할 수 있습니다.
                                    </p>        
                                </motion.div>
                            </motion.div>
                        </div>
                        <div className='work-detail-information-container work-detail-information-container-11'>
                        <div className="container-11-wrap">
                            <motion.div
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true }}
                                className="work-detail-information-img work-detail-information-img-01 fade-up">
                                <motion.img
                                    variants={variants}
                                    src={
                                        process.env.PUBLIC_URL +
                                        '/site/res/image/lexus/img_lexus_sec13_text.webp'
                                    }
                                    alt="렉서스 이미지"
                                />
                            </motion.div>
                            <motion.div
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true }}
                                className="work-detail-information-img work-detail-information-img-02 fade-up">
                                <motion.div className="video-wrap"
                                variants={variants}
                                onAnimationComplete={() => {
                                    if (scrollImgRef03.current) {
                                        scrollImgRef03.current.classList.add('ani-on')
                                    }
                                } }
                                >
                                    <div className='cont-img-mockup'>
                                    </div>
                                    <div className='cont-img-scrollview'>
                                        <img
                                        ref={scrollImgRef03}
                                        src={
                                        process.env.PUBLIC_URL +
                                        '/site/res/image/lexus/img_sec13_view_pc.webp'
                                    }
                                    alt="렉서스 이미지"
                                        ></img>
                                    </div>
                                </motion.div>
                                
                            </motion.div>
                            <motion.div
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true }}
                                className="work-detail-information-img work-detail-information-img-03 fade-up">
                                <motion.img
                                    variants={variants}
                                    src={
                                        process.env.PUBLIC_URL +
                                        '/site/res/image/lexus/img_lexus_sec13_02_pc.webp'
                                    }
                                    alt="렉서스 이미지"
                                />
                            </motion.div>
                            <div className='desc'>
                            많은 콘텐츠 사이에서 필요로 하는 정보를 빠르게 파악할 수 있도록 카드 UI를 통해 정보를 그룹핑 하였으며,<br/>넓은 여백과 그림자를 적용하여 시원한 공간감으로 카드 UI를 시각적으로 드러냄과 동시에 복잡도를 낮췄습니다.
                            <br/>
                            <br/>
                            마이카 페이지는 렉서스 오너의 스마트한 차량 관리를 돕기 위해 차량과 관련된 모든 콘텐츠를 담고 있으며,
                            <br/>하단에 고정된 ‘서비스 예약’ 버튼을 통해 차량 정비를 위해 쉽고 빠르게 서비스 센터를 예약할 수 있습니다. 
                            </div>
                            </div>     
                        </div>
                        <div className='work-detail-information-container work-detail-information-container-12'>
                            <motion.div
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true }}
                                className="work-detail-information-img work-detail-information-img-01 fade-up">
                                <motion.img
                                    variants={variants}
                                    src={
                                        process.env.PUBLIC_URL +
                                        '/site/res/image/lexus/img_lexus_sec14_pc.webp'
                                    }
                                    alt="렉서스 이미지"
                                />
                            </motion.div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    );
}
